





























































































import {Component, Ref, Vue} from "vue-property-decorator";
import {
  AttachmentHostType, AuditFlowScope, LecturerDto, ServiceApplyDto, TrainApplyDto
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import {AuditBodyDto} from "@/utils/customDto";

import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";

@Component({
  name: "ServiceApplyDetail",
  components: {
    AuditBlock,
    AttachmentsView,
  },
  filters: {
    formatStatus(status?: string) {
      switch (status) {
        case "Draft":
          return "草稿";

        case "Published":
          return "已发布";

        case "Deleted":
          return "已删除";
      }
    },
  },
})
export default class ServiceApplyDetail extends Vue {


  dataId?: number;
  detail: ServiceApplyDto = {
    user:{},
    service: {},
    userExtend:{},
    volunteer:{}
  };
  baseURL: any;
  loading = true;


  created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      this.fetchDetail();
      this.loading = false;
      console.log(this.detail)
    }
  }

  // 获取详情
  async fetchDetail() {
    await api.serviceApply.get({ id: this.dataId }).then((res) => {
      this.detail = { ...res };
    });
  }

  getTime(item:any){
    return moment(item).format("HH:mm");
  }

  // 下载附件
  downLoad(item: any) {
    window.location.href = `${this.baseURL}/api/services/app/Attachment/Download?id=${item.id}`;
  }

  get auditApi(){
    return {
      canAudit: api.serviceApply.canAudit,
      userAudit: api.serviceApply.audit
    } as AuditApi
  }

}
